<template>
  <Overlay
    :visible="isDateRequestSidebarOpen"
    @click="toggleDateRequestSidebar(false)"
  />
  <Drawer
    ref="dateRequestSidebarRef"
    class="w-full sm:w-[600px] p-8 pb-32 flex flex-col !bg-gray-50"
    :class="{
      'bg-contain bg-no-repeat bg-bottom': privateDateRequestCompleted,
    }"
    :style="
      privateDateRequestCompleted && {
        backgroundImage:
          'url(\'/_nuxt3-static/events/date-request-success.svg\')',
      }
    "
    :is-open="isDateRequestSidebarOpen"
    @click:close="handleDateRequestSidebarClose"
  >
    <div class="flex flex-col justify-between flex-1">
      <DateRequestForm
        v-if="
          isDateRequestSidebarOpen &&
          !dateRequestCompleted &&
          !privateDateRequestCompleted
        "
        @on-complete-request="dateRequestCompleted = true"
        @on-complete-private-request="privateDateRequestCompleted = true"
      />
      <DateRequestSuccess
        v-if="dateRequestCompleted"
        @onCloseDateRequest="dateRequestCompleted = false"
      />
      <DateRequestSuccessAndShare v-if="privateDateRequestCompleted" />
    </div>
  </Drawer>
</template>

<script lang="ts" setup>
const dateRequestSidebarRef = ref(null)

const { toggleDateRequestSidebar, isDateRequestSidebarOpen } = useUiState()
const { t } = useI18n()

const dateRequestCompleted = ref(false)
const privateDateRequestCompleted = ref(false)

const computedTitle = computed(() => {
  return !privateDateRequestCompleted.value
    ? t('product.dateRequest.sidebarTitle')
    : t('product.dateRequest.sidebarSuccessTitle')
})

const handleDateRequestSidebarClose = () => {
  toggleDateRequestSidebar(false)
  privateDateRequestCompleted.value = false
  dateRequestCompleted.value = false
}

defineExpose({ toggleDateRequestSidebar })
</script>
<style>
body.prevent-scroll {
  height: 100vh;
  overflow-y: hidden;
  position: fixed;
  left: 0;
  right: 0;
}
</style>
