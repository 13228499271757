<script setup lang="ts">
import { Menu, MenuButton, MenuItems, MenuItem } from '@headlessui/vue'
import { useRoute } from 'vue-router'
const { locales, locale } = useI18n()
const buttonId = useId()
const switchLocalePath = useSwitchLocalePath()
const route = useRoute()
const shouldShowLanguagePicker = computed(
  () => !route.name?.includes('magazine')
)
withDefaults(
  defineProps<{
    footerBlockItems: any[]
    displayNewsletterSubscriptionForm?: boolean
  }>(),
  {
    footerBlockItems: () => [],
    displayNewsletterSubscriptionForm: true,
  }
)
const selectedLocale = computed(
  () => locales.value?.find((l) => l.code === locale.value)?.label
)
</script>
<template>
  <NewsletterSubscriptionForm v-if="displayNewsletterSubscriptionForm" />
  <div
    class="pb-20 md:pb-24 -mb-1 bg-teal-900"
    :class="{
      'pt-40 md:pt-44': displayNewsletterSubscriptionForm,
      'mt-20': !displayNewsletterSubscriptionForm,
    }"
  >
    <div
      class="max-w-screen-xl mx-auto p-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4"
    >
      <div
        v-for="column in footerBlockItems"
        :key="`footer-column-${column.title}`"
        class="mt-4 cursor-pointer"
      >
        <FooterBlock :title="column.title" :items="column.items" />
      </div>
      <div v-if="shouldShowLanguagePicker" class="mt-4">
        <FooterBlock :title="$t('general.language')" :items="[]">
          <template #block-items>
            <Menu as="div" class="relative inline-block">
              <MenuButton
                :id="buttonId"
                class="inline-flex w-full justify-center rounded-md bg-white border border-gray-300 px-6 py-3 text-sm font-medium text-gray-800 focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75"
              >
                {{ selectedLocale }}
              </MenuButton>
              <MenuItems
                class="absolute flex flex-col bg-white p-2 bottom-full rounded-md"
              >
                <MenuItem v-for="locale in locales" :key="locale.code">
                  <NuxtLink
                    class="mr-4 p-2"
                    :to="switchLocalePath(locale.code)"
                    >{{ locale.label }}</NuxtLink
                  >
                </MenuItem>
              </MenuItems>
            </Menu>
          </template>
        </FooterBlock>
      </div>
    </div>
  </div>
</template>
