<template>
  <div class="date-request-container">
    <Alert
      v-if="
        !isPrivateEventRequest &&
        dateRequestSidebarRequestPreferredLocation === 'user_location'
      "
      class="my-8 kft-alert--warning"
      :content="
        t('product.dateRequest.eventSuggestions.cantBeHeldAtCustomLocation')
      "
    />
    <form
      class="w-100 pb-4"
      data-test-id="contact-form"
      @submit.prevent.stop="requestEventDates()"
    >
      <DateRequestUserInfo
        v-model="form"
        :event-data="eventData"
        :is-event-request-opened-from-event-page="
          isEventRequestOpenedFromEventPage
        "
        :is-private-event-request="isPrivateEventRequest"
        :errors="errors"
        :invalid-keys="invalidKeys"
        @clear-errors="(field) => clearErrors(field)"
      />
      <PrivateEventDate
        v-if="isPrivateEventRequest"
        v-model="form"
        :event-data="eventData"
      />
      <DateRequestDates
        v-else-if="isDateRequestApplicable"
        v-model="form"
        :event-data="eventData"
        :errors="errors"
        :invalid-keys="invalidKeys"
        @clear-errors="(field) => clearErrors(field)"
      />
      <label
        v-if="isDateRequestApplicable"
        class="cursor-pointer block text-sm"
      >
        <Tooltip
          :key="
            $t(
              'product.subscribeToEventUpdatesModal.firstStep.form.dateType.toolTipText'
            )
          "
          :tooltip-text="
            $t(
              'product.subscribeToEventUpdatesModal.firstStep.form.dateType.toolTipText'
            )
          "
          :disabled="!eventGetters.getIsTeamEvent(eventData)"
          length="large"
          type="span"
          class="self-center mb-2"
        >
          <input
            id="checkbox"
            v-model="privateDateTypeChecked"
            :disabled="eventGetters.getIsTeamEvent(eventData)"
            type="checkbox"
            class="private-date-request-checkbox"
          />
          {{
            $t(
              'product.subscribeToEventUpdatesModal.firstStep.form.dateType.label'
            )
          }}
        </Tooltip>
      </label>
      <label v-if="isPrivateEventRequest" class="cursor-pointer block text-sm">
        <input
          id="checkbox"
          v-model="canUserBeContactedChecked"
          type="checkbox"
          class="mt-4 mr-2"
        />
        {{
          $t(
            'product.subscribeToEventUpdatesModal.firstStep.form.confirmContactCheckbox.label'
          )
        }}
      </label>
      <span
        v-if="!isContactConfirmationChecked && isPrivateEventRequest"
        class="text-red text-sm block"
      >
        {{ $t('general.form.validation.required') }}
      </span>
      <Button
        class="mt-4 w-full sm:w-auto"
        :disabled="loading"
        @click.prevent="handleSubmitButtonClick"
      >
        <span>
          {{
            isDateRequestApplicable || isPrivateEventRequest
              ? $t(
                  'product.subscribeToEventUpdatesModal.firstStep.requestDatesButtonLabel'
                )
              : $t('product.dateRequest.eventSuggestions.seeMoreButton')
          }}
        </span>
      </Button>
    </form>
  </div>
</template>

<script lang="ts" setup>
import { toast, type ToastOptions } from 'vue3-toastify'
import { useFormValidation } from '~/composables/useFormValidation'
import 'vue3-toastify/dist/index.css'
import type { EventDateType } from '~/composables/types/event'
const ISO8601Timestamps = ref([])
const canUserBeContactedChecked = ref(false)
const isContactConfirmationChecked = ref(true)
const router = useRouter()
const localePath = useLocalePath()
const eventData = useEventData()
const loading = ref(false)

const form = ref<{
  email: string
  phone: string
  name: string
  surname: string
  numberOfTickets: string
  companyName: string
  dateType: EventDateType
  dates: string[]
}>({
  email: '',
  phone: '',
  name: '',
  surname: '',
  numberOfTickets: '',
  companyName: '',
  dateType: 'PUBLIC',
  dates: [],
})

const rules = ref({
  email: 'required|email',
  name: 'required|min:3',
  surname: 'required|min:3',
  phone: 'required|phoneNumber',
  numberOfTickets: `required|numeric|min:${eventGetters.getMinDateRequestTickets(
    eventData.value
  )}|max:${eventGetters.getMaxDateRequestTickets(eventData.value)}`,
})

const { t } = useI18n()
const emit = defineEmits([
  'on-complete-request',
  'onCloseDateRequest',
  'on-complete-private-request',
])

const {
  toggleDateRequestSidebar,
  isEventRequestOpenedFromEventPage,
  dateRequestSidebarRequestLocation,
  dateRequestSidebarRequestPreferredLocation,
} = useUiState()
const { errors, clearErrors, validate, invalidKeys } = useFormValidation(
  form,
  rules
)

const privateDateTypeChecked = ref(eventGetters.getIsTeamEvent(eventData.value))

const isPrivateEventRequest = computed(() => {
  if (form.value?.numberOfTickets === '') return true
  return form.value?.numberOfTickets > 7
})

const isDateRequestEnabledForTheEvent = computed(() =>
  eventGetters.isDateRequestActive(eventData.value)
)

const isDateRequestApplicable = computed(() => {
  return (
    isEventRequestOpenedFromEventPage.value &&
    !isPrivateEventRequest.value &&
    isDateRequestEnabledForTheEvent.value
  )
})

const handleSubmitButtonClick = () => {
  if (isPrivateEventRequest.value) {
    requestPrivateEvent()
    return
  }
  if (isDateRequestApplicable.value) {
    requestEventDates()
    return
  }

  handleClickSeeMore()
}
const handleClickSeeMore = () => {
  toggleDateRequestSidebar(false)
  emit('onCloseDateRequest')
  router.push({
    path: localePath('search'),
  })
}

const requestEventDates = async () => {
  loading.value = true

  rules.value = {
    ...rules.value,
    phone: 'phoneNumber',
    dates: 'required|array',
  }
  if (form.dates?.length === 0) {
    loading.value = false
    return
  }
  await validate()

  if (errors.value !== null && Object.keys(errors.value)?.length > 0) {
    loading.value = false
    return
  }

  try {
    await kftApiRequest('/v1/store/date-requests', {
      body: {
        event_description_id: eventGetters.getId(eventData.value),
        email: form.value.email,
        first_name: form.value.name,
        phone: form.value.phone,
        last_name: form.value.surname,
        number_of_tickets: parseInt(form.value.numberOfTickets, 10),
        date_type: privateDateTypeChecked?.value ? 'PRIVATE' : 'PUBLIC',
        last_page_seen: window.location.href,
        dates: form.value.dates
          .filter((date) => date !== '')
          .map((date) => date.replace('T', ' ')),
      },
    })
    sendDateRequestForm(dateRequestSidebarRequestLocation.value)
    emit('on-complete-request')

    toast(
      t(
        'product.subscribeToEventUpdatesModal.secondStep.requestDatesSuccessMessage'
      ),
      {
        autoClose: 2000,
        type: 'success',
        position: toast.POSITION.TOP_CENTER,
        multiple: false,
      } as ToastOptions
    )
  } catch (e) {
    toast(e.data?.message || t('general.error'), {
      autoClose: 2000,
      type: 'error',
      position: toast.POSITION.TOP_CENTER,
      multiple: false,
    } as ToastOptions)
  } finally {
    loading.value = false
  }
}
const requestPrivateEvent = async () => {
  loading.value = true
  rules.value = {
    ...rules.value,
    phone: 'required|phoneNumber',
    numberOfTickets: `required|numeric|min:8|max:5000`,
  }
  delete rules.value?.dates
  isContactConfirmationChecked.value = canUserBeContactedChecked.value
  // await validate()

  if (errors.value !== null && Object.keys(errors.value)?.length > 0) {
    loading.value = false
    return
  }

  if (!canUserBeContactedChecked.value) {
    loading.value = false
    return
  }

  try {
    await kftApiRequest('/v1/store/private-event-requests', {
      body: {
        company_name: form.value?.companyName,
        event_description_id: eventGetters.getId(eventData.value),
        email: form.value.email,
        first_name: form.value.name,
        phone: form.value.phone,
        last_name: form.value.surname,
        number_of_tickets: parseInt(form.value.numberOfTickets, 10),
        date: form.value.dates[0]
          ? form.value.dates[0]?.replace('T', ' ') + ':00'
          : undefined,
        last_page_seen: window.location.href,
        ...(dateRequestSidebarRequestPreferredLocation.value
          ? {
              preferred_location:
                dateRequestSidebarRequestPreferredLocation.value,
            }
          : {}),
      },
    })
    sendPrivateEventRequestForm(dateRequestSidebarRequestLocation.value)
    emit('on-complete-private-request')

    toast(
      t(
        'product.subscribeToEventUpdatesModal.secondStep.requestDatesSuccessMessage'
      ),
      {
        autoClose: 2000,
        type: 'success',
        position: toast.POSITION.TOP_CENTER,
        multiple: false,
      } as ToastOptions
    )
  } finally {
    loading.value = false
  }
}

watch(
  () => isPrivateEventRequest.value,
  () => {
    clearErrors()
    isContactConfirmationChecked.value = true
  }
)
watch(
  () => canUserBeContactedChecked.value,
  () => {
    isContactConfirmationChecked.value = canUserBeContactedChecked.value
  }
)
</script>
<style scoped lang="postcss">
.private-date-request-checkbox {
  @apply mt-4 mr-2;
  &:disabled {
    @apply cursor-not-allowed;
  }
}
</style>
