<template>
  <Overlay :visible="isWaitlistSidebarOpen" @click="toggleWaitlistSidebar" />
  <Drawer
    ref="dateRequestSidebarRef"
    class="w-full sm:w-[600px] p-8 pb-4 flex flex-col !bg-gray-50"
    :is-open="isWaitlistSidebarOpen"
    @click:close="toggleWaitlistSidebar"
  >
    <template #header>
      <h3
        v-if="isWaitlistSidebarOpen"
        class="md:text-xl text-base font-semibold sticky w-full top-0"
      >
        {{
          $t(
            `product.subscribeToEventUpdatesModal.${
              subscribed ? 'secondStep' : 'firstStep'
            }.title`
          )
        }}
      </h3>
    </template>

    <div class="flex flex-col justify-between flex-1">
      <div v-if="!subscribed">
        <p class="">
          {{ $t('product.subscribeToEventUpdatesModal.firstStep.description') }}
        </p>

        <form class="w-100" @submit.prevent.stop="fnSubmit()">
          <div class="el-form-validation-margin mt-2 col-12">
            <label class="required form-label" for="email">{{
              $t(
                'product.subscribeToEventUpdatesModal.firstStep.form.email.label'
              )
            }}</label>
            <div class="position-relative">
              <input
                id="email"
                v-model="form.email"
                :placeholder="
                  $t(
                    'product.subscribeToEventUpdatesModal.firstStep.form.email.placeholder'
                  )
                "
                autocomplete="email"
                type="email"
                class="w-full border border-gray-200 rounded-md p-2 bg-white mt-2"
                name="email"
                @input="clearErrors('email')"
              />

              <i
                class="bi bi-envelope-fill position-absolute top-50 start-0 ms-3 fs-4 translate-middle-y text-black-50"
              ></i>
            </div>
            <span v-if="invalidKeys.includes('email')" class="text-red text-sm">
              {{ errors.email }}
            </span>
          </div>
        </form>
        <button
          :class="[
            'shadow-md transition-all ease hover:shadow-lg hover:scale-[102%] w-100 text-white bg-primary hover:bg-teal-900 w-full mt-4 py-2.5 rounded',
            'kft-buy-box__add-to-cart-button',
          ]"
          @click="requestAddToWaitlist"
        >
          {{
            $t(
              'product.subscribeToEventUpdatesModal.firstStep.submitButtonLabel'
            )
          }}
        </button>
      </div>

      <div v-else>
        <p class="mb-0 overline-text">
          {{
            $t('product.subscribeToEventUpdatesModal.secondStep.description')
          }}
        </p>

        <div
          class="subscribe-to-event-updates__icon-block mt-7 py-2 px-4 d-flex align-items-center"
        >
          <i class="bi bi-envelope-check-fill me-2"></i>
          <span class="overline-text">
            {{
              $t(
                'product.subscribeToEventUpdatesModal.secondStep.iconBlockText'
              )
            }}</span
          >
        </div>

        <Button class="w-full" @click.prevent="toggleWaitlistSidebar">
          {{
            $t(
              'product.subscribeToEventUpdatesModal.secondStep.submitButtonLabel'
            )
          }}
        </Button>
      </div>
    </div>
  </Drawer>
</template>

<script setup lang="ts">
import { toast, type ToastOptions } from 'vue3-toastify'
import 'vue3-toastify/dist/index.css'
import { useFormValidation } from '@/composables/useFormValidation'

const eventData = useEventData()

const { t } = useI18n()
const form = ref({ email: '' })
const subscribed = ref(false)

const waitlistSidebarRef = ref(null)

const { toggleWaitlistSidebar, isWaitlistSidebarOpen } = useUiState()

defineExpose({ toggleWaitlistSidebar })

const rules = ref({
  email: 'required|email',
})

const { errors, clearErrors, validate, invalidKeys } = useFormValidation(
  form,
  rules
)

const requestAddToWaitlist = async () => {
  await validate()

  if (errors.value !== null && Object.keys(errors.value)?.length > 0) {
    toast(t('general.error'), {
      autoClose: 2000,
      type: 'error',
      position: toast.POSITION.TOP_CENTER,
      multiple: false,
    } as ToastOptions)

    return
  }

  try {
    await kftApiRequest(
      `v1/store/events/${eventGetters.getId(eventData.value)}/subscribe`,
      {
        body: {
          email: form.value.email,
        },
      }
    )
    sendWaitlistForm()
  } catch (e) {}

  subscribed.value = true
}
</script>
<style>
body.prevent-scroll {
  height: 100vh;
  overflow-y: hidden;
  position: fixed;
  left: 0;
  right: 0;
}
</style>
